/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     OUESTADAM CONFIDENTIAL                                  !
  !                                                             !
  !     OUESTADAM Common Software                               !
  !     (C) COPYRIGHT OUESTADAM 2024                            !
  !     Licensed Internal Code - Property of OUESTADAM          !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : oeCarousel.css                                     !
  !  Desc. : Common ReactJs Carousel Styles                     !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 16/02/2024                                         !
  !                                                             !
  !  0.1: Creation                                              !
  +-------------------------------------------------------------+
*/
html {
    height: 100vh;
}

body {
    height: 100%;
}

.carousel-image {
    vertical-align: middle;
    width: 100%;
    height: 100%;
}

.carousel-container {
    position: relative;
    margin: auto;
}

.carousel-item {
    width: 100%;
    height: 100%;
}

.prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 10px;
    margin-top: -22px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    border-radius: 0 3px 3px 0;
    user-select: none;
}

.next {
    right: 2px;
    border-radius: 3px 0 0 3px;
}

.prev {
    left: 2px;
    border-radius: 3px 0 0 3px;
}

@keyframes prevnext {
    from {opacity: 1;}
    to {opacity: 0;}
}

.prev:hover, .next:hover {
    background-color: rgba(0, 0, 0, 50%);
    opacity: 1;
}

/* Caption text */
.carousel-caption-bottom {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 0;
    width: 100%;
    position: absolute;
    bottom: 10px;
    text-align: center;
    text-shadow: 1px 2px 0 black;
}

.carousel-caption-bottom-outside {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 0;
    width: 100%;
    position: relative;
    bottom: -10px;
    text-align: center;
    text-shadow: 1px 2px 0 black;
    z-index: 0;
}

.carousel-caption-bottom-outside-mobile {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 0;
    width: 100%;
    position: relative;
    bottom: 20px;
    text-align: center;
    text-shadow: 1px 2px 0 black;
    z-index: 0;
}

.carousel-caption-none {
    display: none
}

.carousel-caption-center {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 0;
    width: 100%;
    position: absolute;
    bottom: 50%;
    top: 45%;
    text-align: center;
    text-shadow: 1px 2px 0 black;
}

.carousel-caption-top {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 0;
    width: 100%;
    position: absolute;
    top: 8px;
    text-align: center;
    text-shadow: 1px 2px 0 black;
}

.dots {
    margin-top: 10px;
    text-align: center;
    position: absolute;
    bottom: 10px;
    width: 100%;
}

.slide-number {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
}

.dot {
    cursor: pointer;
    height: 8px;
    width: 8px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.active, .dot:hover {
    background-color: #717171;
}

.fade {
    -webkit-animation-name: fade;
    -webkit-animation-duration: 1.5s;
    animation-name: fade;
    animation-duration: 1.5s;
}

.pause-icon {
    position: absolute;
    /* top: 38%; */
    left: 15px;
    bottom: 2px;
    /* right: 50%; */
    opacity: 1;
}

.pause {
    -webkit-animation-name: pause;
    -webkit-animation-duration: 0.5s;
    animation-name: pause;
    animation-duration: 0.5s;
}

.bar {
    width: 100%;
    background-color: #ddd;
    position: absolute;
    bottom: 0;
    border-radius: 0 0 10px;
}

.progress {
    width: 1%;
    height: 5px;
    background-color: #042baa;
}

.thumbnails {
    display: flex;
    margin-top: 15px;
    padding-bottom: 10px;
    align-items: initial;
    overflow: auto;
    scrollbar-width: thin;
    opacity: 0;
    position: relative;
    z-index: 1;
    background-color: black;
}

.thumbnails:hover {
    opacity: 1;
}

.thumbnails::-webkit-scrollbar {
    display: none;
}

.thumbnail {
    margin: 0 5px;
}

.active-thumbnail {
    border: #ad3131 solid 3px;
}

@keyframes pause {
    0% {
        opacity: .2;
    }
    90% {
        opacity: 1;
    }

}

@-webkit-keyframes pause {
    0% {
        opacity: .2;
    }
    90% {
        opacity: 1;
    }

}

@-webkit-keyframes fade {
    from {
        opacity: .4
    }
    to {
        opacity: 1
    }
}

@keyframes fade {
    from {
        opacity: .4
    }
    to {
        opacity: 1
    }
}

@media only screen and (max-width: 500px) {
    .prev,
    .next,
    .carousel-caption-bottom,
    .carousel-caption-center,
    .carousel-caption-top,
    .slide-number {
        font-size: 20px !important;
    }

    .dot {
        height: 4px;
        width: 4px;
    }

    .thumbnail {
        max-width: 70px;
    }

    .carousel-caption-bottom {
        bottom: 25px;
    }
}

.MuiPaper-root {
    background-color: black !important;
}

.box:focus {
    outline-width: 0;
}